'use strict';
/**
 * FlashService for show a message to user
 */
angular.module("eTroskovnikApp");

app.factory("FlashService", function($rootScope,$timeout) {
  return {
    show: function(message) {
      $rootScope.flash = message;
      $timeout(function(){
    	  $rootScope.flash = "";
    	}, 3000);
    },
    clear: function() {
      $rootScope.flash = "";
    }
  }
});
