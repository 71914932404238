'use strict';
/**
 * PageController is used for user pages like login, logout and register
 */
angular.module("eTroskovnikApp");

app.controller('AuthController', function($scope, $location,$timeout,AuthenticationService,SessionService,reCAPTCHA){
	
	$scope.credentials 				= {username: "", password: "", remember: ""};
	$scope.register_credentials 	= {username: "", password: "", name: "", surname: "", email: ""};
	$scope.recovery_credentials 	= {email: ""};
	
	$scope.login = function(){
		AuthenticationService.login($scope.credentials).success(function(data){
			$scope.credentials = { remember_token: data.remember_token, name:data.name, surname:data.surname}
			window.location.reload();
		}).error(function(){
			
		});
	}
	$scope.register = function(){
		if($scope.registerForm.$valid) {
			AuthenticationService.register($scope.register_credentials).success(function(){
				$timeout(function(){ 
					$location.path('/confirm');
				},3000);			
			});
		}
	}
	
	$scope.logout = function(){
		AuthenticationService.logout().success(function(){
			$location.path('/');
		});
	}
	$scope.recovery = function(){
		if($scope.recoveryForm.$valid) {
			AuthenticationService.recovery($scope.recovery_credentials).success(function(){
				$timeout(function(){ 
					$location.path('/');
				},3000);			
			});
		}
	}
	$scope.confirm = function(){
		if($scope.confirmForm.$valid) {
			AuthenticationService.confirm($scope.confirm_user).success(function(){
				$location.path('/');
			});
		}
	}
	if(AuthenticationService.isLoggedIn()){
		$scope.name = SessionService.get('name');
		$scope.surname = SessionService.get('surname');
		$scope.admin = SessionService.get('admin');
		$scope.loginProfileTemplate = "siteapp/blocks/profileleft.html";
	}
	else {
		$scope.loginProfileTemplate = "siteapp/blocks/loginleft.html";
	}
});