'use strict';
/**
 * AuthenticationService is service that work with authentication users
 */
angular.module("eTroskovnikApp");

app.factory("SessionService", function() {
	  return {
	    get: function(key) {
	      return sessionStorage.getItem(key);
	    },
	    set: function(key, val) {
	      return sessionStorage.setItem(key, val);
	    },
	    unset: function(key) {
	      return sessionStorage.removeItem(key);
	    }
	  }
	});