'use strict';
/**
 * PageController is used for other pages
 */
angular.module("eTroskovnikApp");

app.controller('PageController', function($scope, AuthenticationService){
	$scope.logout = function() {
	    AuthenticationService.logout().success(function() {
	      $location.path('/login');
	    });
	  };
  $('.carousel').carousel({
	    interval: 2500
	  });
});
app.controller('SettupController', function($scope){
	$scope.date = new Date();	
});