'use strict';
/**
 * AuthenticationService is service that work with authentication users
 */
angular.module("eTroskovnikApp");

app.factory("ContactService", function($http, $location,FlashService){
	
	 var formError = function(response) {
		    FlashService.show(response.flash);
		  };
		  
	return {
		sendcontact: function(data){
			var contact = $http.post("contact/sendcontact", data);
			contact.success(FlashService.clear);
			contact.success(formError);
			contact.error(formError);
			return contact;
		},
	}
});