'use strict';
/**
 * AuthenticationService is service that work with authentication users
 */
angular.module("eTroskovnikApp");

app.factory("AuthenticationService", function($http, $sanitize, SessionService, FlashService, CSRF_TOKEN) {

	var uncacheSession = function() {
	    SessionService.unset('authenticated');
	    SessionService.unset('userid');
	    SessionService.unset('remember_token');
	    SessionService.unset('name');
	    SessionService.unset('surname');
	    SessionService.unset('admin');
	  };

	  var formError = function(response) {
	    FlashService.show(response.flash);
	  };

	  var sanitizeCredentials = function(credentials) {
	    return {
	    	username: $sanitize(credentials.username),
	    	password: $sanitize(credentials.password),
	    	csrf_token: CSRF_TOKEN
	    };
	  };
	  
	  var sanitizeCredentialsConfirm = function(credentials) {
		    return {
		    	email: $sanitize(credentials.email),
		    	code: $sanitize(credentials.code),
		    	csrf_token: CSRF_TOKEN
		    };
		  };
	
	return {
		login: function(credentials){
			var login = $http.post("user/login", sanitizeCredentials(credentials));
			login.success(function(data) {                  
				SessionService.set('authenticated', true);
			    SessionService.set('userid', data.id);
			    SessionService.set('remember_token', data.remember_token);
			    SessionService.set('name', data.name);
			    SessionService.set('surname', data.surname);
			    SessionService.set('admin', data.admin);
			});
			login.success(FlashService.clear);
			login.error(formError);
			return login;
		},
		register: function(userdata){
			var register = $http.post("user/register", userdata);
			register.success(FlashService.clear);
			register.success(formError);
			register.error(formError);
			return register;
		},
		recovery: function(userdata){
			var recovery = $http.post("user/recovery", userdata);
			recovery.success(FlashService.clear);
			recovery.success(formError);
			recovery.error(formError);
			return recovery;
		},
		confirm : function(userdata){
			var recovery = $http.post("user/confirm", sanitizeCredentialsConfirm(userdata));
			recovery.success(FlashService.clear);
			recovery.success(formError);
			recovery.error(formError);
			return recovery;
		},
		logout: function(){
			var logout = $http.get('user/logout');
			logout.success(uncacheSession);
		    return logout;
		},
		isLoggedIn : function(){
			return SessionService.get('remember_token');
		}
	}
});