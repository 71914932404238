'use strict';
var app = angular.module("eTroskovnikApp", ['ngRoute','ngSanitize','reCAPTCHA']).constant("CSRF_TOKEN", '{{ csrf_token() }}')

app.config(['$httpProvider',function($httpProvider) {

	  var logsOutUserOn401 = function($location, $q, SessionService, FlashService) {
	    var success = function(response) {
	      return response;
	    };

	    var error = function(response) {
	      if(response.status === 401) {
	        SessionService.unset('authenticated');
	        $location.path('/login');
	        FlashService.show(response.data.flash);
	      }
	      return $q.reject(response);
	    };

	    return function(promise) {
	      return promise.then(success, error);
	    };
	  };

	  //$httpProvider.responseInterceptors.push(logsOutUserOn401);

	}]);

app.config(['$routeProvider', function($routeProvider) {
		
	$routeProvider.when("/", {
	    	templateUrl: "siteapp/partials/home.html", 
	    	controller:'PageController'
	    		}
	    )
	    .when("/myCarousel", {
	    	templateUrl: "siteapp/partials/home.html", 
	    	controller: "PageController"
	    		}
	    )
	    // Pages
	    .when("/about", {
	    	templateUrl: "siteapp/partials/about.html", 
	    	controller: "PageController"
	    		}
	    )
	    .when("/pricing", {
	    	templateUrl: "siteapp/partials/pricing.html", 
	    	controller: "PageController"
	    		}
	    )
	    .when("/contact", {
	    	templateUrl: "siteapp/partials/contact.html", 
	    	controller: "ContactController"
	    		}
	    )
		// Login page
	    .when('/login', {
			templateUrl: 'siteapp/partials/login.html',
			controller: 'AuthController'
		})
		// Login page
	    .when('/logout', {
			controller: 'AuthController'
		})
	    //register page
	    .when("/register", {
	    	templateUrl: "siteapp/partials/register.html", 
	    	controller: "AuthController"
	    })
	    //password recovery page
	    .when("/recovery", {
	    	templateUrl: "siteapp/partials/recovery.html", 
	    	controller: "AuthController"
	    })
	    .when("/confirm", {
	    	templateUrl: "siteapp/partials/confirm.html", 
	    	controller: "AuthController"
	    })
		}]);
app.config(function (reCAPTCHAProvider) {

    // required, please use your own key :)
    reCAPTCHAProvider.setPublicKey('6LcM7wATAAAAAKm3ccdUK9qzlah30IgX5JptUZg7');

    // optional
    reCAPTCHAProvider.setOptions({
        theme: 'clean'
    });
})
app.run(function($rootScope, $location, AuthenticationService,FlashService){
	
	var routesThatRequireAuth = ['/secure/:any/:any'];
	var authRoutes = ['/login','/register','/recovery'];

	  $rootScope.$on('$routeChangeStart', function(event, next, current) {
	    if(_(routesThatRequireAuth).contains($location.path()) && !AuthenticationService.isLoggedIn()) {
	      $location.path('/');
	      FlashService.show("Please log in to continue.");
	    }
	    if(_(authRoutes).contains($location.path()) && AuthenticationService.isLoggedIn()) {
		      $location.path('/');
		      FlashService.show("You are login.");
		}
	  });
	});